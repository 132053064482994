import { AddressEditor } from '../../../webmodule-common/other/ui/address-editor';
import { BaseModal } from '../../../webmodule-common/other/ui/modal/modal-factory';
import {
  canClose,
  ErrorAbandon,
  isAutoSaving,
  SavePromptOptions,
  saveWithIndicator
} from '../../../webmodule-common/other/save-workflow';
import { checkValidations } from '../../../webmodule-common/other/ui/data-entry-screen-helpers';
import { clone } from '../../../webmodule-common/other/clone';
import { createConfirmCancelButtons } from '../../../webmodule-common/other/ui/modal-footer-buttons';
import { customElement, property } from 'lit/decorators.js';
import { DataBinding } from '../../../webmodule-common/other/ui/databinding/databinding';
import { DataTracker, FieldType } from '../../../webmodule-common/other/ui/databinding/data-tracker';
import { emptyAddress, isAddressValid } from '../../../webmodule-common/other/ui/maps/map-helpers';
import { emptyGuid, newGuid } from '../../../webmodule-common/other/api/guid';
import { FormInputAssistant } from '../../../webmodule-common/other/ui/templateresult/form-input-assistant';
import { getSettingsManager } from '../../supplier/common/supplier-settings';
import { html, TemplateResult } from 'lit-html';
import { isEmptyOrSpace } from '../../../webmodule-common/other/ui/string-helper-functions';
import { LitElement } from 'lit';
import { showDevelopmentError } from '../../../webmodule-common/other/development-error';
import { SupplierSettingsManager } from '../../supplier/common/supplier-settings-manager';
import { tlang } from '../../../webmodule-common/other/language/lang';
import { WillCallLocation } from '../../api/supplier-api-interface-supplier';
import { FormInputSelectValue } from '../../../webmodule-common/other/ui/templateresult/form-input-types';

@customElement('webmodule-supplier-location-editor')
export class WebModuleSupplierLocationEditor extends LitElement {
  dataTracker: DataTracker = new DataTracker(new DataBinding(this, null));

  constructor() {
    super();
    const addField = (
      fieldName: string,
      propertyType?: FieldType,
      nullable?: boolean,
      editorFieldName?: string,
      data?: () => any
    ) => {
      this.dataTracker.addObjectBinding(
        data ?? (() => this.location),
        fieldName,
        editorFieldName ?? fieldName,
        propertyType ?? FieldType.string,
        nullable ?? false
      );
    };

    addField('title', FieldType.string, false);
    addField('type', FieldType.string, false);
    this.dataTracker.eventChange = () => this.locationChangedEvent();
  }

  private _location?: WillCallLocation | undefined;

  public get location(): WillCallLocation | undefined {
    return this._location;
  }

  @property()
  public set location(value: WillCallLocation | undefined) {
    this._location = clone(value);
  }

  dispatchCustom(name: string, values: object) {
    const options = {
      detail: { ...values },
      bubbles: true,
      composed: true
    };

    //wm-sle WebModule-SupplierLocationEditor
    this.dispatchEvent(new CustomEvent(`wm-sle-${name}`, options));
  }

  locationChangedEvent() {
    this.dispatchCustom('changed', {
      location: this.location
    });
  }

  render() {
    const forms = new FormInputAssistant(this.dataTracker, false);
    forms.immediateBindingUpdate = true;

    const typeOption = (type: string): FormInputSelectValue => {
      return {
        value: type,
        text: tlang`${type}`,
        disabled: false
      };
    };

    const locationTypes = [typeOption('Manufacturing Hub'), typeOption('Depot'), typeOption('Branch')];

    return html` <form class="form-two-col will-call-form">
      <div class="row will-call-info">
        <div class="form-column">${forms.textRequired('title', tlang`Title`, 100)}</div>
        ${forms.radioGroupArray('type', locationTypes, { title: tlang`Type`, required: true, class: 'will-call-type' })}
      </div>
      <wm-addresseditor
        id="location-address-editor"
        .address=${this.location?.willCallAddress}
        .title=${tlang`Address`}
        .readonly=${false}
        .isDefaultShippingVisible=${false}
        .isShippingNotesVisible=${false}
        @wm-ae-changed=${(e: Event) => this.addressModified(e.currentTarget as AddressEditor)}
      ></wm-addresseditor>
    </form>`;
  }

  protected createRenderRoot(): HTMLElement | DocumentFragment {
    return this;
  }

  private addressModified(addressEditor: AddressEditor) {
    if (this.location && addressEditor) {
      this.location.willCallAddress = addressEditor.address ? clone(addressEditor.address) : emptyAddress();
      this.locationChangedEvent();
    }
  }
}

class LocationEditDialog extends BaseModal {
  abandoned?: boolean;
  item: WillCallLocation;
  saved = false;
  public mode? = 'Edit';
  private settingsManager: SupplierSettingsManager = getSettingsManager();
  private needsSaving = false;

  constructor(item: WillCallLocation) {
    super();
    this.item = item;
  }

  get getLocation(): WillCallLocation {
    return this.item;
  }

  get isFooterVisible(): boolean {
    return true;
  }

  protected get modalSize() {
    return 'modal-lg';
  }

  async title(): Promise<TemplateResult | string> {
    return tlang`${this.mode} Will Call Details`;
  }

  public getValidationErrors(): string[] {
    const errors: string[] = [];

    const location = this.getLocation;

    if (isEmptyOrSpace(location.title)) errors.push(tlang`Please provide a valid Title`);

    if (isEmptyOrSpace(location.type)) errors.push(tlang`Please provide a valid Type`);

    if (!isAddressValid(location.willCallAddress)) errors.push(tlang`Please provide a valid Address`);

    return errors;
  }

  updateLocation(location: WillCallLocation) {
    this.item = location;
    this.needsSaving = true;
  }

  async bodyTemplate(): Promise<TemplateResult> {
    const setLocation = (e: CustomEvent<{ location: WillCallLocation }>) => {
      e.stopImmediatePropagation();
      this.updateLocation(e.detail.location);
    };

    return html` <webmodule-supplier-location-editor .location="${this.getLocation}" @wm-sle-changed="${setLocation}">
    </webmodule-supplier-location-editor>`;
  }

  async performAutoSave(options: SavePromptOptions): Promise<boolean> {
    //if we dont need to save, then just exit.
    //but if we are not in autosave mode, we are going to force a save.
    let needsSave = false;
    try {
      needsSave = await options.needsSaveEvent();
    } catch (e) {
      await showDevelopmentError(e as Error);
      return false;
    }

    //this is to let us abandon new items that have had no editing
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    if (!needsSave) throw new ErrorAbandon('Cancel', tlang`Cancel New Edit`);

    if (!(await this.checkValidations())) return false;
    if (isAutoSaving() && !needsSave) return true;

    //this is our basic save indicator
    return await saveWithIndicator(async () => await this.internalSaveData());
  }

  async internalSaveData() {
    if (this.item.id === emptyGuid) {
      this.item.id = newGuid();

      await this.settingsManager.addWillCallLocation(this.item);
    } else {
      await this.settingsManager.updateWillCallLocation(this.item);
    }

    this.saved = await this.settingsManager.saveSettings();
    return this.saved;
  }

  getAutoSavePromptOptions(): SavePromptOptions {
    return {
      isReadonly: false,
      autoSaveEvent: async options => {
        return await this.performAutoSave(options);
      },
      dictionaryName: 'Supplier Locations',
      needsSaveEvent: async () => this.needsSaving,
      abandonSaveEvent: async () => {
        this.abandoned = true;
        this.needsSaving = false;
        return true;
      },
      displaySaveModal: false,
      informationDispatcher: undefined
    };
  }

  async canClose(): Promise<boolean> {
    return this.abandoned || (await canClose(this.getAutoSavePromptOptions()));
  }

  footerTemplate(): TemplateResult | null | undefined {
    const okEvent = async () => {
      if (await this.canClose()) {
        await this.hideModal();
        return;
      }
    };
    const closeEvent = async () => await this.hideModal();
    return createConfirmCancelButtons(tlang`Save Changes`, okEvent, tlang`Cancel`, closeEvent);
  }

  protected async checkValidations(): Promise<boolean> {
    const errors = this.getValidationErrors();
    return await checkValidations(errors);
  }
}

export async function editLocation(item: WillCallLocation | null = null) {
  const location =
    item ??
    new (class implements WillCallLocation {
      title = '';
      type = 'Manufacturing Hub';
      id = emptyGuid;
      willCallAddress = emptyAddress();
    })();

  const dialog = new LocationEditDialog(location);

  if (!item) dialog.mode = 'Add';

  await dialog.showModal();
  return dialog.saved;
}
